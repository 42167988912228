<template>
    <modal ref="modalEditarFactura" titulo="Editar factura" adicional="Guardar" @adicional="editarFactura">
        <ValidationObserver ref="validator">
            <div class="row my-3 mx-0 f-14 justify-content-center">
                <div class="col-8 my-2">
                    <p class="input-label-top">Editar factura</p>
                    <ValidationProvider name="categoría" v-slot="{ errors }" rules="required">
                        <el-input v-model="numero_factura" size="small" class="w-100" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
           numero_factura: ''
        }
    },
    computed: {
        ...mapGetters({
           
        }),
        id(){
            return this.$route.params.id_pedido;
        }
    },
    methods: {
        ...mapActions({
            
        }),
        toggle(){
            this.$refs.modalEditarFactura.toggle()
        },    
        editarFactura(){
            this.$refs.modalEditarFactura.toggle()
        },    
    }
}
</script>

<style lang="css" scoped>
</style>
