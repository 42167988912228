<template>
    <modal ref="verFacturasAsociadas" titulo="Facturas asociadas">
        <div class="container">
            <div class="row mb-3 titulo-divisor">
                <div class="col-auto my-auto">
                    <p class="f-600 f-14 ucfirst">Facturas</p>
                </div>
                <div class="col my-auto pl-0">
                    <hr class="my-0 bg-white" />
                </div>
                <div class="col-auto my-auto"></div>
            </div>
           <div class="row">
               <div v-for="(factura, index) in facturas" :key="index" class="col-10 mb-3" >
                    <p class="input-label-top">Factura</p>
                    <div class="d-flex icon-option">
                        <el-input v-model="model[factura.referencia]" class="w-100" size="small" placeholder="N° factura" />
                        <i class="icon-trash-can-outline f-20 ml-2"
                            :class="permitAction('almacen.pedido.eliminar.factura.orden') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.pedido.eliminar.factura.orden', deleteFacture, [factura.id])"
                        />
                    </div>
               </div>
           </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            datePicker: '',
            numFactura: '',
            model: {},
            facturas:[]
        }
    },
	computed: {
		...mapGetters({
			user: 'auth/user',
		})
	},
    methods: {
        ...mapActions({
            Action_get_informacion_ocs: 'almacen/pedidosInternosOcs/Action_get_informacion_ocs',
            Action_delete_factura_orden_compra: 'almacen/pedidosInternosOcs/Action_delete_factura_orden_compra'
        }),
        toggle(facturas){
            this.facturas = facturas
            this.$refs.verFacturasAsociadas.toggle()
            this.facturas.forEach(factura => {
                this.model[factura.referencia] = factura.referencia
            });
        },
        guardarFacturaAsociada(){
            this.toggle()
        },
        async deleteFacture(id){
            await this.Action_delete_factura_orden_compra({id: this.$route.params.id, id_pedidos_internos_ordenes_compras_facturas: id})
            this.facturas = this.facturas.filter(factura => factura.id != id)
            this.$emit('reload')
        }
    }
}
</script>

<style lang="css" scoped>
</style>
