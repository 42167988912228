<template>
    <section class="ver-oc">
        <navbar-admin />
        <div class="row my-4">
            <div class="col-12 mb-md-3 col-lg-9">
                <div class="bg-f9 border p-3 br-4">
                    <div class="row mb-2">
                        <div v-if="informacion_ocs.estado == 1" class="col-12 col-lg-8">
                            <div class="d-middle">
                                <p class="f-18 f-700">Orden de compra {{informacion_ocs.id}}</p>
                                <i class="icon-circle-check f-18" style="color: rgb(60, 210, 115);"/>
                                <p class="f-14 font-italic f-500">Entrega completada {{informacion_ocs.fecha_creado | helper-fecha('DD MMM YYYY')}}</p>
                            </div>
                        </div>
                        <div v-else class="col-12 col-lg-8">
                            <div class="d-middle">
                                <p class="f-18 f-700">Orden de compra {{informacion_ocs.id}}</p>
                                <i class="icon-progress-pencil f-18" style="color: #ff8000;"/>
                                <p class="f-14 font-italic f-500"> Pendiente {{informacion_ocs.fecha_creado | helper-fecha('DD MMM YYYY')}}</p>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 text-md-left text-xl-right">
                            <p class="f-600 f-18">
                               $ {{ formatoMoneda(informacion_ocs.valor) }}
                            </p>
                        </div>
                    </div>
                    <!-- proveedor + forma de pago -->
                    <div class="row f-14 mb-1">
                        <div class="col-12 col-lg-6">
                            <p class="f-600">
                                Proveedor:
                                <span class="f-300">{{informacion_ocs.proveedor}}</span>
                            </p>
                        </div>
                        <div class="col-12 col-lg-6 text-md-left text-xl-right">
                            <p class="f-600">
                                Fecha:
                                <span class="f-300">{{informacion_ocs.fecha_actualizacion | helper-fecha('DD MMM YYYY')}}</span>
                            </p>
                        </div>
                    </div>

                    <div class="row f-14 mb-1">
                        <div class="col-12 col-lg-6">
                            <p class="f-600">
                                Proyecto: 
                                <span class="f-300">{{informacion_ocs.proyecto}}</span>
                            </p>
                        </div>
                        <div class="col-12 col-lg-6 text-md-left text-xl-right">

                        </div>
                    </div>
                    <div class="row f-14 mb-1">
                        <div class="col-12 col-lg-6">
                            <p class="f-600">
                                Cliente:  
                                <span class="f-300">{{informacion_ocs.clientes}}</span>
                            </p>
                        </div>
                        <div class="col-12 col-lg-6 text-md-left text-xl-right">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-md-3 col-lg-3 pl-xl-0">
                <div class="bg-f9 border px-2 py-2 br-4">
                    <ValidationObserver ref="validator">
                        <el-popover v-model="show" placement="bottom" trigger="click" width="200" :disabled="!permitAction('almacen.pedido.asociar.factura.orden')">
                            <p class="text-center text-general f-600 m-3">Factura</p>
                            <hr class="bg-general m-0" />
                            <div class="m-3">
                                <ValidationProvider name="referencia" rules="required" v-slot="{ errors }">
                                    <p class="input-label-top">Número de factura</p>
                                    <el-input v-model="selected_id_factura" size="small" class="mb-3" clearable filterable />
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                                <ValidationProvider name="fecha de pago" rules="required" v-slot="{ errors }">
                                    <p class="input-label-top">Fecha de pago</p>
                                    <el-date-picker
                                    v-model="fechaPago"
                                    type="date"
                                    :placeholder="actualTime"
                                    size="small"
                                    class="mb-3"
                                    clearable
                                    filterable
                                    :style="`width:142px`"
                                    />
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                                <ValidationProvider name="Valor del pago" rules="required" v-slot="{ errors }">
                                    <p class="input-label-top">valor</p>
                                    <el-input v-model="valorPago" size="small" class="mb-3" clearable filterable />
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                                <button class="btn btn-general w-100 f-12" @click="asociateBill">Asociar</button>
                            </div>
                            <div slot="reference" class="bg-5d text-white f-600 text-center cr-pointer br-4 f-12 py-2 mb-3"
                                :class="permitAction('almacen.pedido.asociar.factura.orden') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click="functionPermitAction('almacen.pedido.asociar.factura.orden')"
                            >
                                Asociar factura
                            </div>
                        </el-popover>
                    </ValidationObserver>
                    <div v-if="informacion_ocs.facturas" class="row">
                        <div v-for="(item, index) in informacion_ocs.facturas.slice(0,3)" :key="index" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-3">
                            <div class="bg-f5 border br-4 d-middle justify-content-between px-1 f-600">
                                <p class="f-12">Factura: {{item.referencia}}</p>
                                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                    <template #content>
                                        <p class="p-2 cr-pointer item" @click="eliminarFacturaAsociada(item.id)">Eliminar</p>
                                    </template>
                                    <i class="icon-dots-vertical f-18" 
                                        :class="permitAction('almacen.pedido.eliminar.factura.orden') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('almacen.pedido.eliminar.factura.orden')"
                                    />
                                </el-tooltip>
                            </div>
                        </div>
                        <div v-if="informacion_ocs.facturas.length > 3"  class="col-12 col-md-6 col-lg-12 col-xl-6 mb-2 mb-xl-3 mt-auto">
                            <p class="cr-pointer f-12 f-500 text-right" @click="verMasFacturas" >Ver más</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <indicador-progress-value 
                :totalValue="informacion_ocs.valor"
                :actualValue="informacion_ocs.valor_pagado"
                :lastDate="informacion_ocs.valor_pagado_fecha"/>
            </div>
            <div class="col-2 my-auto text-right">
                <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                    <template #content>
                        <p class="p-2 cr-pointer item" @click="modalAbrirReportarPago">Reportar pago</p>
                        <p class="p-2 cr-pointer item" @click="modalAbrirFinalizarPago">Finalizar pago</p>
                    </template>
                    <el-button :disabled="informacion_ocs.estado == 1" class="btn btn-general px-3 f-12"
                        :class="permitAction('almacen.pedido.actualizar.pago.orden') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.pedido.actualizar.pago.orden')"
                    >Actualizar pago</el-button>
                </el-tooltip>
            </div>
        </div>
        <titulo-divisor titulo="Ordenes de compra" class="my-4">
            <div class="row">
                <div class="col auto px-1">
                    <el-input v-model="buscarListado" placeholder="Buscar" size="small" />
                </div>
                 <div class="col-auto my-auto px-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div class="col-auto my-auto pl-1">
                    <el-button v-if="informacion_ocs.estado == 0" class="btn btn-general f-12 px-4" @click="abrirModalEntregaCompleta">Entrega completa</el-button>
                </div>
            </div>
        </titulo-divisor>
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usar-servidor="true" :usar-paginacion="true"  :servidor-data="materiales" @paginar="listarMateriales">
            <el-table-column label="Ref" prop="ref">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos f-600 cr-pointer">{{ scope.row.id }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="material" width="350">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ scope.row.material }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.tipo }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Valor unitario" prop="valorUnitario" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">
                        {{ formatoMoneda(scope.row.valor_unitario) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Total de unidades" prop="totalUnidades" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.total_unidades }}
                    </p>
                </template>
            </el-table-column>
            <!-- <el-table-column label="Unidades de la orde" prop="unidadesOrden" align="center">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.unidadesOrden" size="small" class="w-50" />
                </template>
            </el-table-column> -->
        </tabla-general>
        <!-- partials  -->
        <modal-editar-factura ref="modalEditarFactura" />
        <modal-eliminar ref="abrirEliminarFacturaAsociada" title="Eliminar factura" mensaje="¿Está seguro que quiere eliminar esta factura?" @delete="handleDeleteFactura"/>
        <modal-reportar-pago ref="reportarPago" @report="reportarPago"/>
        <modal ref="abrirModalFinalizarPago" titulo="Finalizar pago" adicional="Finalizar" @adicional="finalizarPago">
            <div class="row m-3 f-12">
                <p class="col-12 text-center">¿Está seguro que quiere finalizar este pago?</p>
            </div>
        </modal>
        <modal ref="abrirModalEntregaCompleta" titulo="Entrega completa" adicional="Enviar" @adicional="enviarOrden">
            <div class="row m-3 f-12">
                <p class="col-12 text-center">¿Está seguro que quiere realizar la entrega completa?</p>
            </div>
        </modal>
        <modal-ver-mas-facturas ref="modalVerMasFacturas" :facturas="facturas" @reload="listarInformacionOcs" />
         <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" clearable filterable placeholder="Seleccionar tipo material" size="small" class="w-100">
                        <el-option v-for="item in filtroDetalleOrdenCompra.tipos_materiales" :key="item.id" :label="item.tipo" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango total de unidades</p>
                    <el-slider v-model="range_valores" range :min="filtroDetalleOrdenCompra.unidades_min" :max="filtroDetalleOrdenCompra.unidades_max" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="listarMateriales()"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import modalEditarFactura from './partials/modalEditarFactura'
import modalVerMasFacturas from './partials/modalVerMasFacturas'
import moment from 'moment';
import modalReportarPago from './partials/modalReportarPago'
export default {
    components: {
        modalEditarFactura,
        modalVerMasFacturas,
        modalReportarPago
    },
    data() {
        return {
            visibleEditarEtapa: false,
            numeroFacturaAsociada: '',
            buscarListado: '',
            facturas:[],
            show: false,
            idFactura: null,
            selected_id_factura: '',
            fechaPago: null,
            valorPago: 0,
            selected_bills: [],
            actualTime: moment().format('Y-MM-D')
        }
    },
    computed: {
        ...mapGetters({
            select_bills: 'selects/selects/select_bills',
            filtroDetalleOrdenCompra: 'almacen/pedidosInternosOcs/filtroDetalleOrdenCompra',
            informacion_ocs: 'almacen/pedidosInternosOcs/informacion_ocs',
            materiales: 'almacen/pedidosInternosOcs/materiales',
            filtroDetalleOcs: 'almacen/pedidosInternosOcs/filtroDetalleOcs',
        }),
        filter: {
            get(){ return this.filtroDetalleOcs }, 
            set(val){ this.set_filtro_detalle_ocs(val) }
        },
        range_valores: {
            get(){
                return [
                    this.filter.unidades_min,
                    this.filter.unidades_max
                ]
            },
            set(val){
                this.filter.unidades_min = val[0]
                this.filter.unidades_max = val[1]
            }
        },
        id_orden(){
            return this.$route.params.id
        }
    },
    created () {
        this.getBreadcumbs([ 'almacen.main', 'almacen.pedidos.internos', 'almacen.pedidos.internos.ordenes.compra' ])
        this.Action_get_select_bills()
        this.listarInformacionOcs()
    },
    methods: {
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_informacion_ocs: 'almacen/pedidosInternosOcs/Action_get_informacion_ocs',
            Action_get_filtros_detalle_ordenes_compras: 'almacen/pedidosInternosOcs/Action_get_filtros_detalle_ordenes_compras',
            Action_get_materiales: 'almacen/pedidosInternosOcs/Action_get_materiales',
            Action_clear_filter_detalle_ocs: 'almacen/pedidosInternosOcs/Action_clear_filter_detalle_ocs',
            Action_delete_factura_orden_compra: 'almacen/pedidosInternosOcs/Action_delete_factura_orden_compra',
            Action_get_select_bills: 'selects/selects/Action_get_select_bills',
            Action_asociar_factura: 'almacen/pedidosInternosOcs/Action_asociar_factura',
            Action_reportar_pago: 'almacen/pedidosInternosOcs/Action_reportar_pago',
            Action_finalizar_pago: 'almacen/pedidosInternosOcs/Action_finalizar_pago',
            Action_entrega_completa: 'almacen/pedidosInternosOcs/Action_entrega_completa'
        }),
        ...mapMutations({
            set_filtro_detalle_ocs: 'almacen/pedidosInternosOcs/set_filtro_detalle_ocs',
        }),
        async listarInformacionOcs(){
            await this.Action_get_filtros_detalle_ordenes_compras(this.$route.params.id)
            await this.Action_get_informacion_ocs(this.$route.params.id)
            await this.limpiarFiltro();
            this.selected_bills = this.informacion_ocs.facturas.map(e => e.id_factura)
        },
        async listarMateriales(page=1){
            await this.Action_get_materiales({id:this.$route.params.id, payload:{page}})
        },
        eliminarFacturaAsociada(id){
            this.idFactura = id
            this.$refs.abrirEliminarFacturaAsociada.toggle()
        },
        modalAbrirReportarPago(){
            this.$refs.reportarPago.toggle()
        },
        modalAbrirFinalizarPago(){
            this.$refs.abrirModalFinalizarPago.toggle()
        },
        abrirModalEntregaCompleta(){
            this.$refs.abrirModalEntregaCompleta.toggle()
        },
        async reportarPago(payload){
            await this.Action_reportar_pago({id: this.id_orden, ...payload})
            this.$refs.reportarPago.toggle()
        },
        async finalizarPago(){
            await this.Action_finalizar_pago(this.id_orden)
            this.$refs.abrirModalFinalizarPago.toggle()
        },
        async enviarOrden(){
            if(this.informacion_ocs.valor != this.informacion_ocs.valor_pagado) await this.Action_finalizar_pago(this.id_orden)
            await this.Action_entrega_completa(this.id_orden)
            this.$refs.abrirModalEntregaCompleta.toggle()
        },
        editarFacturaAsociada(){
            this.$refs.modalEditarFactura.toggle()
        },
        verMasFacturas(){
            this.$refs.modalVerMasFacturas.toggle(this.informacion_ocs.facturas)
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async asociateBill(){
            await this.Action_asociar_factura({id_pedidos_internos: this.id_orden, referencia: this.selected_id_factura, fecha_pago:this.fechaPago, valor:this.valorPago})
            this.show = false
            this.selected_id_factura = ''
            this.selected_bills = this.informacion_ocs.facturas.map(e => e.id_factura)
            this.$refs.validator.reset()
        },
        async limpiarFiltro(){
            this.Action_clear_filter_detalle_ocs()
            await this.listarMateriales()
        },
        async handleDeleteFactura(){
            await this.Action_delete_factura_orden_compra({id: this.id_orden, id_pedidos_internos_ordenes_compras_facturas: this.idFactura})
            this.listarInformacionOcs()
        }
    },

}
</script>

<style lang="scss" scoped>
.ver-oc{
     .icon-dots-vertical{
        &::before{
            margin: 0px;
        }
    }
    .contenedor-progress{
        background: #DDDDDD;
        border-radius: 13px;
        height: 10px;
        position: relative;
        .progress{
            background: #26DE81;
            border-radius: 13px;
            height: 10px;
        }
        .icon-flag-triangle{
            position: absolute;
            top: -14px ;
            font-size: 22px;
        }
    }
}
</style>