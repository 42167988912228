<template>
    <modal ref="reportarPago" titulo="Reportar pago" adicional="Guardar" @adicional="reportarPago">
        <ValidationObserver ref="validator">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-9 mb-3">
                        <ValidationProvider name="fecha de pago" rules="required" v-slot="{ errors }">
                            <p class="input-label-top">Fecha de pago</p>
                            <el-date-picker v-model="model.fecha_pago" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                    <div class="col-9 mb-3">
                        <ValidationProvider name="valor a pagar" rules="required|min_value:0.01" v-slot="{ errors }">
                            <p class="input-label-top">Valor a pagar</p>
                            <CustomCurrencyInput v-model="model.pago" :options="{ currency: 'USD' }" placeholder="Valor a pagar"/>
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import moment from 'moment'
const model = {
    fecha_pago: '',
    pago: ''
}
export default {
    data(){
        return {
            model: {...model}
        }
    },
    methods: {
        toggle(){
            this.$refs.reportarPago.toggle()
        },
        reset(){
            this.model = {...model}
            this.$refs.validator.reset()
        },
        async reportarPago(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return
            
            const payload = {
                fecha_pago: moment(this.model.fecha_pago).format('YYYY-MM-DD'),
                valor: this.model.pago
            }

            this.$emit('report', payload)
            this.reset()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
